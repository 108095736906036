// Global 
@media (max-width: 430px) {
    form#bodyForm {
        overflow: hidden;
    }
}

.homeFeatures {
    display: none;
}

.homeFeedBox2 {
    padding-top: 0em;
}

.subMenu {
    background: $white;
}

body {
    overflow-x: hidden;
}

.button {
    // padding: 25px 40px;
    // background-color:blue;
    white-space: pre;
    font-weight: 500;
    text-transform: uppercase;
    &.btn-outline {
        background: $white;
        border: 1.5px solid $brand-primary;
        color: $brand-primary;
        padding: 13.5px 28.5px;
        &:hover {
            background-color: $brand-primary;
            color: $white;
        }
    }
}

// Sub-banners
.pagePostPage,.blogPostPage,.newsPostPage  {
    // For No Banner
    &.noBanner {
        .headerText{
            margin-top: 0;
        }
    }
    .headerText {
        margin-top: -8em;
        background-color: $brand-primary;
        .title {
            color: $white;
            margin-top: 0.5em;
        }
        &:after {
            content: none;
            @media (max-width: 1024px) {
                content: none;
            }
        }
        .breadcrumbItem {
            color: $white;
            &:before {
                color: $white;
            }
        }
        .breadcrumbLink {
            a {
                color: #f2a253;
            }
        }
   
    }
    .carousel  {
        max-width: 100%;
        padding:0;
    }
}

.headerText {
    background-color: $brand-primary;
    padding: 2em 20px;
    margin-top: -7em;
    position: relative;
    border-radius: 5px;
    color:$white;
    width: 100%;
    margin: auto;
    margin-top: 6em;
    a {
        color: $accent-orange;
    }
    .breadcrumbItem:before {
        color: $white;
    }
    .title {
        color: $body-colour;
    }
    @media(max-width: 1024px) {
        margin-top: -7em;
    }
}

.post .contentBlockWrapper {
    padding-top: 5em;
    .button {
        white-space: unset;
    }
}

.post .postContent .postFooterWrapper {
    // display: none;
    .shareWrapper {
        display: flex;
        align-items: center;
        strong {
            margin: 0;
        }
        .shareList {
            margin-left: 2em;
            li a {
                &:before {
                    color: $black;     
                }
                &:hover {
                    &:before {
                        color: $brand-primary;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// Sub-banner END
#menuAdmin {
    display: flex !important;
    align-items: center;
    .menuAdminBasket{
        order: unset
    }
    a.reciteme {
        background: rgba(#5c5080, 0.1);
        padding: 1em;
        display: flex;
    }
    
    li a {
        &:after {
            content: "";
            background-image: url($assets-path + "chervon-right.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-left: 10px;
        }
    }
    li.menuAdminSuportal:before {
        content: "";
        width: 1px;
        height: 10px;
        display: inline-block;
        margin-right: 11px;
        background: #5c5080;
    }
    @media (max-width: 820px) {
        display: none;
    }
}

.top-content {
    display:flex;
    align-items: center;
    justify-content: end;
    margin: 0 auto;
    max-width: 1280px;
    .cta-top {  
        display: none;
        padding-right: 1em;
        &:after {
            content: "";
            background-image: url($assets-path + "chervon-right.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-left: 10px;

        }
        &.login {
            &:before {
                content: "";
                width: 1px;
                height: 10px;
                display: inline-block;
                margin-right: 11px;
                background: #5c5080;
    
            }
        }
    }
    a.reciteme {
        background: rgba( #5c5080, 0.1);
        padding: 1em;
        display: flex;
    }
    @media (max-width:820px) {
        display: none;
    }
}

// Header
.mainLogo {
    aspect-ratio: 1 / 1;
    height: 200px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 820px) {
        bottom: unset;
    }
}

.menuAdminContainer {
    background-color: $bg-light;
    .searchContainer {
        display: none;
    }
    img:not(.mediaImage) {
        width: 100px;
        height: 25px;
    }
    
}

.menuMainAlt {
    margin: 0 0 0 5px;
}

.headerContent {
    position: relative;
    justify-content: flex-end;
    #menuMain{
        order: 2;
        background-color: transparent;
        width: max-content;
        margin-right: 0;
        @media (min-width: 1025px) {
            margin-left: 230px;
            .topLevel {
                gap: 1em;
                margin-right: 1em;
            }
            &:not(.active) {
                max-height: none !important;
            }
        }
        .topLevel li a {
            color: $body-colour;
            font-weight: 400;
            text-transform: uppercase;
            // padding:0;
            &:after {
                color: $body-colour;

            }
            &:hover {
                color: $brand-primary;
                border-bottom: 1px solid;
                &:after {
                    color: $brand-primary;
                }
            }
        }
        .header-search {
            display: none;
        }
    }
    @media(max-width: 1024px) {
        #menuMain{
            background-color: $brand-secondary;
            position: absolute;
            top: 74px;
            left: 0;
            width: 100%;
            .topLevel li a {
                color: $white;
                &:hover {
                    color: $white;  
                    &:after {
                        color: $white;   
                    }
                }
                &:after {
                    color: $white;
                }
            }
        }
        .menuMain .header-search button:before {
            color: $white;
        }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        #menuMain {
            padding-top: 40px;
        }
    }
}

.top-header-wrapper .bottom-content {
    // margin-top: 0.5em;
    .cta-wrapper {
        display: flex;
        justify-content: end;
        padding: 0;
        margin: 0;
        .cta {
            list-style: none;
        }
        .cta:nth-of-type(1) {
            padding-right: 0.5em;
        }
    }
}

// responsive header
#pageHeader {
    @media (max-width: 767px) {
        .mainLogo {
            height: 90px;
        }
        #menuAdmin {
            padding: 0 0 0 1em;
            justify-content: space-between;
            li.menuAdminSuportal:before {
                margin-right: 1em;
            }
        }
        .top-header-wrapper .bottom-content .cta-wrapper {
            padding: 20px 15px 10px;
            justify-content: stretch;
            gap: 10px;
            .cta {
                flex: 1;
                padding: 0;
                a {
                    width: 100%;
                    padding: 15px;
                    margin: 0;
                }
            }
        } 
    }
    @media (max-width: 399px) {
        #menuAdmin {
            padding: 0;
            flex-wrap: wrap;
            justify-content: center;
            li {
                position: relative;
                flex: 1;
                text-align: center;
                a:after {
                    display: none;
                }
            }
            li.menuAdminSuportal:before {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            a.reciteme {
                width: 100%;
                justify-content: center;
            }
        }
    }
}

//Carousell- section  1 
.homepage {
    .carousel {
        a.carouselControlPrev {
            opacity: 0.5;
        }
        max-width: 100%;
        padding: 0;
        .carouselDetailWrapper {
            margin-top: -11em;
            @media(max-width:1024px) {
                margin-top: 0;   
            }
            .carouselSlideDetail {
                padding: 2em;
                padding-bottom: 6em;
                background-color: $brand-primary;
                position: relative;
                min-height: 340px;
                border-radius: 5px;
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    right: -10px;
                    width: 450px;
                    height: 295px;
                    background-image: url($assets-path + "pink-smudge.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .carouselSlideHeading {
                    width: 70%;
                    @media (max-width:1024px) {
                        width: 100%;
                    }
                    a {
                        color: $white;
                    }
                }
                .carouselSlideSummary {
                    width: 65%;
                    color: $white;
                       @media (max-width: 1024px) {
                        width: 58%;
                        @media (max-width: 820px) {
                            width: 100%;
                        }
                    }
                }
            }
            .carouselSlideReadMore {
                background: transparent;
                border: 1.5px solid $white;
                color: $white;
                font-weight: 500;
                text-transform: uppercase;
                &:hover {
                    background-color: $brand-secondary;
                    color: $white;
                    border: 1.5px solid $brand-secondary;
                }
            }
        }
        .carouselControls {
            justify-content: flex-start;
            max-width: $container-max-width - 40px;
            margin: 0 auto;
            bottom: 0%;
            margin-bottom: 1.5em;
            a {
                background-color: transparent;
                color: $white;
            }
        }
        //Responsive
        @media (max-width: 820px) {
            .carouselDetailWrapper .carouselSlideDetail {
                &:before {
                    content: none;
                }
            }
        }
    }
}

// Intro - Section 2 
#section_one {
    padding: 2em 0em;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: calc(20px - 15px);
    padding-right: calc(20px - 15px);
    position: relative;
    .homeFeaturesTitle {
        display: none;
    }
    .content-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .img-wrapper {
            position: relative;
            width: 450px;
            @media (max-width: 430px) {
                width: 275px;
            }
            // &:before {
            //     content: "";
            //     position: absolute;
            //     bottom: 0px;
            //     left: -10px;
            //     width: 450px;
            //     height: 100px;
            //     background-image: url($assets-path + "pink-smudge.png");
            //     background-size: contain;
            //     background-repeat: no-repeat;      
            // }
            img {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                object-fit: contain;

            }
        }
        .desc-wrapper {
            margin-left: -11em;
            .title {
                color: $brand-primary;
                margin-bottom: 0;
            }
            .desc {
                font-size: 20px;
            }
        }
    }
    //Reponsive 
    @media(max-width: 1024px) {
        .content-wrapper {
            .desc-wrapper {
                margin-left: -8em;
            }
        }
    }
    @media (max-width: 820px) {
        margin-top: 4em;
        padding: 2em 1em;
        .content-wrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .desc-wrapper {
                margin-left: 0;
                .title {
                    text-align: center;
                }
                .desc {
                    text-align: center;
                }
            }
        }
    }
}

//Section 3 - homebox 1 
#section_two {
    min-height: 500px;
    position: relative;
    .bg-img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .content-wrapper {
        background-color: $accent-blue;
        padding: 2em;
        color: $white;
        height: 400px;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: absolute;
        bottom: -3%;
        left: 16.5%;
        border-radius: 5px;
        overflow: hidden;
        @media (min-width: 1700px) {
            left: 11%;
        }
        &:before {
            content: "";
            position: absolute;
            bottom: -35%;
            right: -5%;
            width: 450px;
            height: 450px;
            background-image: url($assets-path + "globe-light@1x.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        .title  {
            color: $white;
        }
        .desc {
            font-size: 20px;
            color: rgba($white, 0.7)
        }
        .button {
            width: max-content;
        }
    }
    @media (max-width: 1024px) {
        .content-wrapper {
            width: 65%;
            bottom: 20%;
            left: 4%;
            height:400px
        }
    }
    @media(max-width: 820px) {
        .bg-img {
            height: 55vh;
        }
    }
    @media (max-width: 430px) {
        .bg-img {
            object-position: right;
        }
        .content-wrapper{
            padding: 2em;
            width: 90%;
            height: 100%;
            position: relative;
            margin-top: -13em;
        }
    }
}

//Section - 4 
#recounting {
    // padding: 2em 0em;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    // padding-top: 5rem;
    // padding-bottom: 5rem;
    padding-left: calc(20px - 15px);
    padding-right: calc(20px - 15px);
    position: relative;
    text-align: center;
    margin-top: 5em;
    .homeFeaturesTitle {
        display: none;
    }
    &.content-wrapper {
        .img-wrapper {
            position: relative;
            width: 375px;
            height: 60px;
            margin: auto;
            margin-bottom: 2em;
            img {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .desc-wrapper {
            .title {
                margin-bottom: 0;
            }
            .desc {
                font-weight: 500;
                width: 50%;
                margin: auto;
                margin-top: 1em;
                font-size: 20px;
                .pink-text {
                    color:$brand-primary;
                }
            }
        }
    }
    @media (max-width: 430px) {
        padding: 2em 2em; 
        &.content-wrapper .img-wrapper  {
            width: 100%;
        }
    }
}

.homeBox4 {
    position:relative;
    &:before {
        content: "";
        position: absolute;
        bottom: 0px;
        right: -10px;
        width: 100%;
        height: 50%;
        background-image: url($assets-path + "bg-img.png");
        background-size: contain;
        background-repeat: repeat-x;
    }
}

section.ExtraFeed {
    .feedList {
        .feedItem{
            background: $white;
        }
    }
}

section.StoriesFeed.homeFeed {
    background: transparent;
    .feedList {
        .feedItem{
            background: transparent;
        }
    }
}

section.LatestnewsFeed,section.StoriesFeed,section.NewsFeed,section.ExtraFeed {
    text-align: center;
    position: relative;
    .feedsTitle {
        display: none;
    }
    .feedList {
        .feedItem {
            border: none;
            .feedItemDetailsWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2em;
            }
            a {
                border-radius: 5px;
                img {
                   width: 450px;
                   height: 320px;
                    @media (max-width: 820px) {
                        width: 100%;
                    }
                }
            }
            .feedTitle  {
                min-height: 100px;
                line-height: 1.1;
                a {
                    color: $body-colour;
                    font-size: 25px;
                    font-weight: 700;
                }
            }
            p {
                margin-bottom: 1em;
            }
            .read-more {
                color: $brand-primary;
                font-weight: 700;
                text-decoration: none;
                padding-right: 1em;
                &:after {
                    content: "";
                    background-image: url($assets-path + "chervon-right-purple.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-left: 10px;
                }     
            }
            .button {
                width: fit-content;
                @media (max-width: 430px) {
                    width: 100%;
                }
            }
        }
    }
    .button {
        &.bottom {
            margin-top: 2em;
        }
    }
}

section.ExtraFeed {
    background-color: $bg-light;
}


//Homefeed overrights
@media (min-width: 577px) {
    .homeFeedBox2 .feedList .homeFeature:nth-child(1), .homeFeedBox2 .feedList .feedItem:nth-child(1) {
        flex: 1;
    }
    .homeFeedBox3 .feedList .homeFeature:nth-child(1), .homeFeedBox3 .feedList .feedItem:nth-child(1) {
        flex: 1;
    }
}

// Stats 
.homefeaturecategory-homeboximpactstats {
    overflow: hidden;
    padding: 2rem 0;
    background-color: $white;
    .homeImpactWrapper {
        .title {
            color: $body-colour;
            margin: 0;
        }
        .impactStat-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: center;
        }
        .impactStat__figure {
            span.countup {
                font-size: $font-size-h2;
            }
        }
        .impactStat {
            width: calc(100% / 3 - 2em);
            margin: 1em;
            padding: 2em;
            &__text {
                color: $body-colour;
            }
            .image-wrapper {
                width: 300px;
                height: 300px;
                margin: auto;
                margin-bottom: 2em;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    @media (max-width: 820px) {
        .homeImpactWrapper .impactStat {
            width: calc(100% / 2 - 2em);
        }
    }
    @media (max-width: 430px) {
        .homeImpactWrapper .impactStat {
            width: calc(100% / 1 - 1em);
            .image-wrapper {
                width: 100%;
            }
        }
    }
}

//Donation Section 
#donation-section {
    min-height: 500px;
    position: relative;
    // .formQuestion.donationAmount.donationSelected {
    //     border:2px solid orange !important;
    // }
    .bg-img-wrapper {
        margin: 0;
        .bg-img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .main-wrapper {
        background-color: $accent-blue;
        padding: 2em;
        color: $white;
        // height:400px;
        width: 80%;
        margin: auto;
        position: relative;
        margin-top: -15em;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .img_wrapper {
            width: 300px;
            margin-bottom: 0.5em;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .content-wrapper {
            display:flex;
            .left {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 2em;
                width: 50%;
                padding-bottom: 0;
                .desc {
                    font-size: 20px;
                }
                label.checkboxRadio {
                    background: $white;
                    color: $black;
                    border-radius: 5px;
                }

                .quickGivingPanel .formQuestion.donationAmount.donationSelected {
                    background-color: orange;
                }

                .quickGivingPanel .formQuestion.donationAmount.donationAmountOther label {
                    background: $white;
                    color: $black;
                    border-radius: 5px;
                }
                .quickGivingPanel {
                    background: none;
                    padding: 2em 0em;
                    margin: 0;
                    .formQuestion.donationAmount {
                        align-items: center;
                        justify-content: center;
                        border: none;
                        border: 3px solid transparent;
                        border-radius: 5px;
                        max-height: 70px;
                        &:hover {
                            border: 3px solid orange;
                        }
                        // &.donationSelected {
                        //     border:2px solid orange; 
                        // }
                        .donationAmountFigure {
                            font-size: 20px;
                            padding: 15px 15px;
                            font-weight: 500;
                            // transition: all 0.3s ease;
                            border: 2px solid transparent;
                            &:hover {
                                border: 2px solid orange;
                            }
                        }
                    }
                    fieldset.selectDonationAmount {
                        display: flex;
                        margin: 0;
                    }
                    .button {
                        margin-left: 0;
                        margin-top: 0;
                        display: flex;
                        align-items: center;
                        margin: 3px;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }         
            }
            .right {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 2em;
                width: 50%;
                justify-content: space-between;
            }
            .title  {
                color: $white;
            }   
            .desc {
                font-size: 20px;
            }
            .button {
                width: max-content;
                font-weight: 500;
                text-transform: uppercase;
                margin: 3px;
                height: 68px;
            }
        }
    }
    // @media (max-width: 1024px) {
    //     .bg-img-wrapper {
    //         height: 50vh;
    //     }
    // }
    @media (max-width: 1024px) {
        .bg-img-wrapper {
            height: 70vh;
        }
        .main-wrapper {
            margin-top: -30em;
            .img_wrapper {
                margin: 0;
                margin-bottom: 1em;
            }
        }
        .main-wrapper .content-wrapper {
            flex-direction: column;
            .left {
                width:100%;
                padding:unset;
                .quickGivingPanel fieldset.selectDonationAmount {
                    flex-direction: column;
                }
                .quickGivingPanel .formQuestion.donationAmount {
                    background-color: $white;
                    label {
                        color: $body-colour;
                    }
                    &:hover {
                        border: 2px solid orange;
                    }
                }
            }
            .right {
                width: 100%;
                padding: unset;
            }
            .button {
                width: 100%
            }
        }
    }
    @media (max-width: 430px) {
        .main-wrapper {
            .img_wrapper {
                width: 100%;
            }
        }
    }
}

#resouces {
    // padding:2em 0em;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    // padding-top: 5rem;
    // padding-bottom: 5rem;
    padding: 2em 0em;
    padding-left: calc(20px - 15px);
    padding-right: calc(20px - 15px);
    position: relative;
    text-align: center;
    padding-top: 4em;
    @media (max-width: 430px) {
        padding: 2em 2em; 
    }
}

//Footer 
.footerBox.NewsletterSign-up {
    background-color: $footer-bg;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        bottom: -5%;
        left: 0;
        width: 450px;
        height: 295px;
        background-image: url($assets-path + "footer-smudge.png");
        background-size: contain;
        background-repeat: no-repeat;
    }
    #footer-wrapper {
        width: 65%;
        margin: auto;
        margin-right: 0;
        width: 100%;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        margin-right: 10%;
        .content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                font-size: 39px;
            }
            .desc {
                width: 50%;
            }
        }
        label {
            font-size: 14px;
        }
        input {
            border-radius: 5px;
            &:focus {
                border-color: transparent;
            }
        }
        button {
            background-color: $brand-primary;
            text-transform: uppercase;
        }
    }
    @media (min-width: 1200px) and (max-width: 1800px) {
        &:before {
            bottom:-5%;
            left: -12%;
        }
        #footer-wrapper {
            width: 70%;
            margin-right: 5%;
        }
    }
    @media (max-width: 1024px) {
        padding: 2em 2em;
        &:before {
            content: none;
        }
        #footer-wrapper {
            .content-wrapper {
                flex-direction: column;
                .desc {
                    width: 100%;
                }
            }
            button {
                width: 100%;
                background-color: $brand-primary;
                text-transform: uppercase;
            }
        }
    }
}

.pageFooter {
    background-color: $footer-bg;
    display: block;
    grid-template-columns: unset;
    .footerBox2 {
        color: rgba($white, 0.5);
        .footer-wrapper {
            margin: 0em 5em;
            .heading {
                margin-bottom: 2em;
                color: $white;
            }
            .footerNav {
                li {
                    padding: 0.4em 0em;
                    a {
                        color: rgba($white, 0.5);
                        text-decoration: none;
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
            .items {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item {
                    width: calc(100% / 4 - 2em);
                    margin: 1em;
                    padding: 2em;
                    a {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &.contact-us {
                        p {
                            a {
                                color: $white;
                                text-decoration: none;
                                font-weight: 700;
                            }
                        }
                    }
                    &.social {
                        .oisc-wrapper {
                            margin-top: 1em;
                            display: flex;
                            justify-content: space-between;
                            align-items: end;
                            .img-wrapper {
                                width: 80px;
                                margin-bottom: 0;
                                margin-left: 0;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .desc {
                                p {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .logo_wrapper {
                            width: 250px;
                            margin: 0;
                            margin-top: 2em;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 1024px) {
        .footerBox2 .footer-wrapper {
            margin: 0em 2em;
            .items .item {
                width: calc(100% / 2 - 2em);
            }
        }
    }
    @media(max-width: 430px) {
        .footerBox2 .footer-wrapper {
            margin: 0em 0em;
            .items .item {
                width: calc(100% / 1 - 1em);
                padding: 0;
            }
        }
    }
}

.footerBox:not([class*="ewsletter"]) {
    grid-row: unset;
}

//Listing Page
.headerText  {
    background-color: transparent;
    a {
        color: $body-colour;
        position:relative;
    }
    .breadcrumbItem {
        color: $body-colour;
        &:before {
            color: $body-colour;
        }
    }
}

.listing {
    .headerWrapper {
        .carousel {
            margin-top: 6em;
            @media (max-width: 820px) {
                margin-top: 0em !important;
            }
        }
    }
    .headerText {
        margin-top: 0em;
        background-color: transparent;
    }
    .listedPostText  {
        h2 {
            font-size: $font-size-h4 !important;
            a {
                color: $body-colour !important;
            }
        }
    }
    .listedItem {
        border: none !important;
        padding: 1em 0;
        &.listedFaq {
            padding: 0 0 1em;
        }
        .listedPostText {
            padding: 1em 0em !important;
            display: flex;
            flex-direction: column;
        }
        p.postSummary {
            padding-bottom: 1em;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .readMore {
            margin: 20px 0 0;
            text-transform: uppercase;
            font-weight: 500;
            width: fit-content;
        }
        .read-more {      
            color: $brand-primary;
            font-weight: 700;
            text-decoration: none;
            padding-right: 1em;
            margin-top: 1em;
            &:after {
                content: "";
                background-image: url($assets-path + "chervon-right-purple.svg");
                background-size: contain;
                background-repeat: no-repeat;
                width: 10px;
                height: 10px;
                display: inline-block;
                margin-left: 10px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .carouselSlideHeading a {
        color: $body-colour;
        @media (max-width:1024px) {
            color: $white;
        }
    }
}

body .faqListingPage {
    .headerText {
        padding-bottom: 0;
    }
    .listedItem p.postSummary {
        padding-bottom: 0;
    }
    .listing .contentBlockWrapper {
        padding-top: 1em;
    }
} 

.pages-Category-our-people {
    blockquote {
        display: none;
    } 
}

body.pages-News-Default.pages-news-default.news-list.newsBody.newsList.page-has-banner.listing-with-feature.js,
.pages-News,
.blogs-stories,
.pages-Events-Category-events,
.pages-Category-our-people,
.pages-FAQs-Category-healthcare,
.blogs-job-opportunities,
.listing-Category-faqs,
.categoryFiltered {
    position: relative;
    overflow-x: hidden;
    .accordianHeader {
        margin: 0 auto;
        transition: all 0.3s ease;
    }
    .contentBlockWrapper {
        font-size: 20px;
    }
    .contentBlockWrapper.accordianBody.hidden {
        /* display: block !important; */
        display: none !important;
    }
    ul.carouselControls {
        display: none;
    }
    a.listed-post-image-link {
        border-radius: 5px;
    }
    &:before {
        content: "";
        position: absolute;
        top: 3%;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: url($assets-path + "bg-img.png");
        background-size: contain;
        background-repeat: repeat-x;
        z-index: -1;
    }
    #startcontent {
        &:hover {
            text-decoration: none;
        }
    }
    .headerText {
        padding: 2em 20px;
        .title, .breadcrumbWrapper {
            padding: 0;
        }
        .listingIntro {
            position: relative;
            font-size: 20px;
            p {
                width: 50%;
            }
            @media (max-width: 1024px) {
                p {
                    width: 50%;
                }
            }
            // &:after {
            //     content: "";
            //     position: absolute;
            //     top: 33%;
            //     width: 100%;
            //     height: 50%;
            //     background-image: url($assets-path + "resources.png");
            //     background-size: contain;
            //     background-repeat: no-repeat;
            //     width: 300px;
            //     height: 80px;
            //     right: 0%;
            //     @media (max-width: 1024px) {
            //         content: none;
            //     }
            // }
        }
    }
    .pageHeader .headerContent {
        // border-bottom: 1px solid rgba($body-colour, 0.1);
        z-index: 99;
        position: relative;
        &:after {
            content: "";
            width: 100vw;
            height: 1px;
            background: rgba($body-colour, 0.1);
            position: absolute;
            bottom: -5px;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
    }
    p {
        color: rgba($body-colour, 0.5) !important;
        @media (max-width: 1024px) {
            // color: $white !important;
        }
    }
}

.categoryFiltered {
    .headerText .listingIntro {
        color: $black !important;
    }
   @media (max-width: 1024px) {
        .headerText .listingIntro {
            color: $black !important;
        }
    }
    blockquote {
        display: none;
    } 
}

//Post 
.post .contentBlock .postAside {
    display: none;
}

.post {
    .contentBlockWrapper {
        max-width: 900px;
    }
}

//Exit btn
.exit_btn {
    /* Add your styles for the button here */
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px 20px;
    background-color: $brand-primary;
    color: #fff;
    border: none;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        color: $white;
        text-decoration: none;
    }
}

#menuAdmin {
    .menuAdminLogin, .menuAdminBasket {
        display: none;
    }
}

.formDonatePage,.appealPostPage {
    @media (max-width: 1024px) {
        .headerText {
            padding: 1em 0em;
            width: 100%;
            margin-top: unset;
            .title {
                // padding-left:0px
                margin-bottom: 0;
            }
        }
        .post .contentBlockWrapper {
            max-width: calc(100% - 40px);
            padding-top: 15px;

        }
    }
    @media (min-width:1280px) {
        .contentBlockWrapper {
            max-width: 1280px;
        } 
        .headerText {
            padding: 0em 0em;
            width: 100%;
            .title {
                padding-left: 0px;
            }
        }
        .post .contentBlockWrapper {
            padding-top: 10px;
        }
    }
}

@media only screen and (min-width: 821px) {
    
}

@media only screen and (max-width: 821px) {
    a.reciteme img {
        height: 34px;
    }
}


//Filter z-index
section.categoryFilterWrapper {
    position: relative;
    z-index: 2;
}

//654706 FAQ post bug
.faqsBody .listedItem .listingIntro {
  display: none;
}
a.reciteme{
&:hover{
  content: url($assets-path + "reciteme-red.png");
}
}